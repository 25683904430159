
.hero__swiper {
  &.swiper-wrapper {
    min-height: 90vh;
  }

  .swiper-slide {
    height: initial;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 150px;
  }
}

.hero__imgWraper {

  &:before {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    display: block;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    transition: all 2s ease-out;
    z-index: 2;

  }

  &:after {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 200px;
    display: block;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    transition: all 2s ease-out;
    z-index: 2;
  }
}


.hero__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
  //transition: all 3s ease-out;
}

.swiper-initialized {
  .hero__img {
    transition: all 3s ease-out;
  }
}

.swiper-slide-active-transition {
  .hero__img {
    transform: translate(-50%, -50%) scale(1.05);

  }

  .hero__txt {
    opacity: 1;
  }
}

.hero__container {
  position: relative;
  z-index: 2;

  .boxDecor {
    padding-bottom: 100px;
    margin-bottom: 0;
  }
}

.swiper2__nav--absolute {
  position: absolute;
  bottom: 135px;

  .container {
    justify-content: flex-start;
    padding-left: 110px;

    .swiper-pagination-bullet {
      background: #fff;
    }

  }
}

.swiper--white {
  .swiper2__nav {
    .swiper-button-prev, .swiper-button-next, .swiper-button-stop {
      filter: brightness(0) invert(1);
      @media(hover: hover) {
        &:hover {
          filter: none;
        }
      }
    }
  }
}


.hero__txt {
  opacity: 0;
  transition: all 2s ease-out;

  h1, h2 {
    line-height: 1.1;
    font-size: $font37;
    color: $white;

  }
}

.hero__data {
  color: rgba(255, 255, 255, 0.5);
  font-size: $font16;
  white-space: nowrap;
}

.hero__info {
  background: rgba(255, 255, 255, 0.85);
  color: $txt;
  gap: 24px;
  padding-left: 15px;
}

.hero__price {
  font-weight: 700;
  color: $main;
  white-space: nowrap;
}

.hero__country {
  display: flex;
  align-items: center;

  img {
    margin-right: 12px;
    height: 26px;
    width: auto;
  }
}

.hero__calendar {
  background: url("../img/ico/ico-calendar.svg") no-repeat center;
  background-size: 20px;
  padding-left: 28px;
  background-position: left center;
  white-space: nowrap;
}


@media(max-width: $grid-breakpoints-sm) {
  .hero__info {
    gap: 15px;

    .btn {
      text-indent: -1000px;
      padding: 0 25px;
      background-position: center;
    }
  }

  .hero__swiper.swiper-wrapper {
    min-height: 400px;
  }
  .hero__country img {
    height: 20px;
    margin-right: 6px;
  }
  .hero__container .boxDecor {
    padding-bottom: 0px;
    padding-left: 0;
    background: none;
  }

  .hero__title {


    h1, h2 {
      margin-bottom: 0;
      padding-bottom: 5px;
      padding-top: 5px;
      font-size: 20px;
    }
  }



  .hero__swiper .swiper-slide {
    padding-bottom: 70px;
  }
  .swiper2__nav--absolute {
    bottom: 10px;

    .container {
      justify-content: flex-start;
      padding-left: 20px;
    }
  }
}