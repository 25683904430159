@import "components/variables";
@import "components/slider-main";


.zp__nav-trigger {
  background: transparent;
  border: none;
  font-size: $font20;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  white-space: nowrap;
  padding: 0 30px 0 0;
  line-height: 3;
  display: block;
  width: 100%;
  font-weight: 700;
  text-align: left;
  position: relative;

  &:after {
    content: url("../img/ico/ico-btn-arrow.svg");
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.3s ease-out;
    position: absolute;
  }

  &[aria-selected="true"] {
    &:after {
      opacity: 1;
    }

    color: #fff;
  }

  @media(hover: hover) {
    &:hover {
      color: #fff;
    }
  }
}

.zp__tab-wrapper {
  display: flex;
  gap: 60px;
}

.zp__navlist {
  flex: 0 0 260px;
  max-width: 260px;
  margin-bottom: 80px;
}

.zp__inner {
  display: flex;
  gap: 50px;

}

.zp__list {
  flex: 0 0 350px;
  column-count: 2;
  column-gap: 20px;
  color: rgba(255, 255, 255, .6);
  line-height: 1.9;

  a {
    color: rgba(255, 255, 255, .6);
    @media(hover: hover) {
      &:hover {
        color: #fff;
      }
    }
  }
}

.zp__right {
  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
  }

  .btn {
    margin-top: 20px;
  }
}


@media(max-width: $grid-breakpoints-xl) {
  .zp__inner {
    display: block;
  }
  .zp__list {
    column-count: 1;
    display: flex;
    gap: 0 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .zp__nav-trigger {
    font-size: $font16;
  }
  .zp__navlist {
    flex: 0 0 210px;
    max-width: 210px;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .zp__tab-wrapper {
    display: block;
  }

  .zp__navlist {
    display: flex;
    max-width: 100%;
    overflow: auto;
    gap: 20px;
    margin-bottom: 30px;
  }
  .zp__nav-trigger {
    padding-right: 25px;

    &:after {
      transform-origin: center;
      transform: rotate(90deg);
      top: 0;
      right: 0px;
    }
  }

}


.trip__navlist {
  display: flex;
  gap: 30px;
  align-items: center;
}

.trip__nav-trigger {
  border: none;
  background: none;
  font-size: 15px;
  font-weight: 700;
  font-family: $font2-family-name;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;

  &[aria-selected="true"] {
    color: $main;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .trip__navlist {
    gap: 15px;
  }
  .trip__navlistWrapper {
    overflow: auto;
    width: 100%;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .trip__nav-trigger {
    white-space: nowrap;
  }
  .trip__navlist {
    gap: 30px;
  }
}


.opinion__slide {
  height: initial;
  padding-bottom: 50px;
}

.opinion__item {
  height: 100%;
  background: #fff;
  border-radius: 0;
  padding: 50px 40px 5px 40px;
  width: 100%;
  box-shadow: 0 -5px 6px rgba(0, 0, 0, .05);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .starWrapper {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &:before {
    content: '';
    border-top: 30px solid #fff;
    border-left: 0px solid transparent;
    border-right: 30px solid transparent;
    height: 0;
    width: 100%;
    position: absolute;
    bottom: -30px;
    right: 0;
    filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 5%));
    z-index: -1;
  }
}

.opinion__title {
  span {
    display: block;
    font-weight: 400;
    font-size: $font12;
    color: $txt-dark;
    margin-bottom: 10px;
  }
}

.opinion__des {
  font-size: $font14;
  font-style: italic;
  font-family: "Merriweather", serif;
  line-height: 1.7;
  padding-bottom: 15px;
}

.opinion__media {
  color: $txt-dark;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;

}

.opinion__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}


@media(max-width: $grid-breakpoints-xl) {
  .opinion__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.dp {
  min-height: 900px;
  padding-bottom: 100px;
  overflow: hidden;

  .boxDecor {

    &:before {
      background-repeat: repeat-y;
      height: calc(100% - 40px);
    }
  }
}

.dp__inner {
  min-height: 80px;
}


.dp__navlist {
  display: flex;
  gap: 30px;
}


.dp__nav-trigger {
  display: block;
  background: none;
  border: none;
  padding: 0;
  color: $white;
  font-weight: 700;
  white-space: nowrap;
  line-height: 48px;
  font-size: $font16;

  &[aria-selected="true"] {
    color: $main;
  }

}

.dp__list {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  gap: 5px 30px;
  font-size: 14px;

  a {
    color: $white;
    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }
  }
}

.dp__tab-wrapper {
  width: 100%;

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border: none;
  }

  ::-webkit-scrollbar-track {
    background-color: #000;
    border-radius: 3px;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #2B360A;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.dP__map {
  margin-top: 30px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 50px;

  .active {
    opacity: 0.8;
  }
}


@media(max-width: $grid-breakpoints-xl) {
  .dp__navlist {
    gap: 5px 20px;


  }
  .dp__navlistWrapper {
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .dp {
    min-height: 770px;
  }
}


.swiper-slide.lastPlaces__slide {
  height: auto;
}

.lastPlaces__link {
  display: flex;
  flex-direction: row;
  background: $white;

  .product__figure {
    height: 100%;
  }

  .product__bottom {
    padding-top: 30px;
  }

  .product__info {
    padding-bottom: 20px;
  }


  .product__last2 {
    margin-left: auto;
  }
}

.lastPlaces__right, .lastPlaces__left {
  flex: 0 0 50%;
  max-width: 50%;

}

.lastPlaces__right {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
}

@media(max-width: $grid-breakpoints-xl) {
  .lastPlaces__right {
    padding: 0 0 10px 0;
  }
}


@media(max-width: $grid-breakpoints-md) {
  .lastPlaces__right {
    padding: 0 0 10px 0;
  }
  .lastPlaces__link {
    display: block;
    position: relative;

    .product__figure {
      height: 0;
    }
  }
  .lastPlaces__left, .lastPlaces__right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .lastPlaces__link .product__last2 {
    width: 18px;
  }

  .product__feature {
    font-size: 9px;
    gap: 5px 20px

  }

  .lastPlaces__left {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    z-index: 2;
    width: 100%;
  }
  .lastPlaces__right {
    padding-top: 65%;

  }
}




